import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can install the PHP SDK using composer with the command listed below:`}</p>
    <h4 {...{
      "id": "using-composer"
    }}>{`Using Composer`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer require paymentsds/mpesa
`}</code></pre>
    <h4 {...{
      "id": "manual-installation"
    }}>{`Manual Installation`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://github.com/paymentsds/mpesa-php-sdk mpesa-php-sdk
cd mpesa-php-sdk
composer install
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      